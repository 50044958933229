div.footer {
  background-color: #58595b;
  margin-top: 20px;
}

img.footerlogo {
  margin-bottom: -100px !important;
}

div.footerdiv {
  margin-top: 20px;
  margin-bottom: 30px;
  padding-bottom: 20px;

  h3{
    font-size: 16px;
  }

  .text-right {
    padding-right: 20px;
  }

  .text-left {
    padding-left: 20px;
  }

}

footer {
  color: #fff;

  	a {
  		color: #ffffff;
  		text-decoration: none;
	}

	a:hover {
	  	color: #ffffff;
	}
}

footer div.input-group {
  margin-top: 12px;
  margin-bottom: 12px;
}

.footer-copyright {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 11px;
}

img.footer-logo{

      margin-top: 21px;

}

@media (max-width: 767px) {
  img.powered-by-ryan {
    display: none;
  }

  img.powered-by-ryan-bottom {
    padding-bottom: 20px;
  }
}

@media (min-width: 768px) {
  img.powered-by-ryan {
    position: absolute;
    right: 22px;
  }

  img.powered-by-ryan-bottom {
    display: none;
  }
}
  