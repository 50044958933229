
// Body
$body-bg: #fff;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-color: #58595b;
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$font-family-sans-serif: 'Open Sans', sans-serif;
$font-size-base: 15px;
$line-height-base: 1.6;
$text-color: #58595b;
$heading-text-color: $brand-color;
$heading-bg-color: #ebebeb;
$sub-heading-bg-color: #ebebeb;


// Navbar
$navbar-default-bg: #fff;
$navbar-color: #939598;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

$link-color: #1d97e9;

