
div.view-appointment{

	h3{
		margin-top: 5px;

	}

}
	
div.setup-appointments,  div.view-appointments{
	.badge {
		background-color: #ff0000;
	}
}

span.appts-available {
	color: green;
	font-size: 20px;
	font-weight: bold;
}
